import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Smarkio: fixed issues when saving data on bycard from chatbot.`}</li>
      <li parentName="ul">{`Dashboards: Added the option to select empty/not empty cells for any fields on rules.`}</li>
      <li parentName="ul">{`Bug fix: New open windows are displayed upfront.`}</li>
      <li parentName="ul">{`V3: The icon and label in the header was changed from Byside to Coremedia.`}</li>
      <li parentName="ul">{`Bug fix: Hyperlink sent on Bycard can be viewed.`}</li>
      <li parentName="ul">{`Bug Fix: Showing correct initial animation according to the right season based on Geo location.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      